import React, { useState, useRef } from "react"
import { graphql } from "gatsby"
import { Dropdown } from "semantic-ui-react"
import LeafletMap from "../../../components/leafletmap"
import SEO from "../../../components/seo"
import useContainerDimensions from "../../../components/useContainerDimensions"
import styles from "./us-facilities.module.css"
import "../../general.css"
import {
    chartTitleTextStyle,
    vizBackgroundStyle
} from "../../../defines";

const Title = "Animal related facilities map (US)";
const DefaultPosition = [37.3, -121.9];
const DefaultZoom = 7;

export const query = graphql`
    query {
        allFacilityListCleanCsv {
            nodes {
                Name
                Lat
                Lon
                Farm_Type
            }
        }
    }
`;

const getMarkersAndCategories = (data) => {
    const result = {
        markers: [],
        categories: {}
    };
    data.forEach(element => {
        let type = element.Farm_Type;
        switch (type) {
            case "Cows (Dairy, Meat)":
                type = "Cows - Dairy/Meat";
                break;
            case "Cows, Bulls, Horses, Other Animals - Rodeos":
                type = "Rodeos";
                break;
            case "Pigs (Meat)":
                type = "Pig farms";
                break;
            case "Cows (Meat)":
                type = "Cows - Meat";
                break;
            case "Chickens (Meat)":
                type = "Chickens";
                break;
            case "Chickens (Eggs)":
                type = "Eggs";
                break;
            case "Mink/Foxes/Rabbits/Other Mammals (Fur)":
                type = "Fur";
                break;
            case "Turkeys (Meat)":
                type = "Turkeys";
                break;
            case "Fish & Marine Animals (Meat)":
                type = "Fish";
                break;
            case "Alligators & Crocodiles (Skin, Meat)":
                type = "Alligators & Crocodiles";
                break;
            case "Sheep (Meat)":
                type = "Sheep";
                break;
            case "Goats (Meat)":
                type = "Goats";
                break;
            case "Rendering (Processing leftover animal body parts from slaughterhouses)":
                type = "Rendering";
                break;
            case "Honey Bees (Honey)":
                type = "Honey";
                break;
            case "Ducks (Meat)":
                type = "Ducks";
                break;
            default:
                break;
        }
        if (!result.categories[type]) {
            result.categories[type] = 0;
        }
        result.categories[type]++;
        result.markers.push({
            type,
            popup: `${element.Name} (${type})`,
            position: [+element.Lat, +element.Lon]
        });
    });
    return result;
};

const filterMarkers = (allMarkers, selectedCategories) => {
    const result = allMarkers.filter(m => selectedCategories.indexOf(m.type) !== -1);
    return result;
}

const FacilitiesList = ({ data, mobile }) => {
    let MapAspectRatio = 4 / 3;
    if (mobile) {
        MapAspectRatio = 3 / 4;
    }

    const markersAndCategories =
        getMarkersAndCategories(data.allFacilityListCleanCsv.nodes);
    let sortedCategories = Object.keys(markersAndCategories.categories).sort((a, b) => markersAndCategories.categories[b] - markersAndCategories.categories[a]);
    const mapDivRef = useRef();
    const { width } = useContainerDimensions(mapDivRef);
    const mapWidth = width;
    const mapHeight = width / MapAspectRatio;

    const initialSet = [sortedCategories[0], sortedCategories[1]];
    if (!mobile) {
        initialSet.push(sortedCategories[2])
    }
    const [selectedCategories, setSelectedCategories] = useState(initialSet);

    const onSelectedCategoriesChanged = (event, result) => {
        let { value } = result || event.target;
        setSelectedCategories(value);
    };

    let map;
    if (mapWidth && mapHeight) {
        const filteredMarkers =
            filterMarkers(markersAndCategories.markers, selectedCategories);
        map =
            <LeafletMap
                position={DefaultPosition}
                zoom={DefaultZoom}
                markers={filteredMarkers}
            />;
    }
    return (
        <>
            <SEO title={Title} />
            <div className="contentDiv">
                <h2 className="chartTitle" style={chartTitleTextStyle(mobile)}>{Title}</h2>
                <div className="vizBackground" style={vizBackgroundStyle(mobile)}>
                    <div className={styles.dropDown}>
                        <Dropdown
                            placeholder="Select facility type(s)"
                            fluid
                            multiple
                            selection
                            options={sortedCategories.map(c => ({ key: c, text: c, value: c }))}
                            defaultValue={selectedCategories}
                            onChange={onSelectedCategoriesChanged}
                        />
                    </div>
                    <div ref={mapDivRef} style={{
                        width: mapWidth > 0 ? mapWidth : null,
                        height: mapHeight > 0 ? mapHeight : null
                    }}>
                        {map}
                    </div>
                    <div className="citation">
                        Data source: <a href="https://www.counterglow.org/">Project Counterglow</a>
                    </div>
                </div>
            </div>
        </>
    )
};

export default FacilitiesList;
